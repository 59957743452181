import { useNavigate, useOutletContext } from "@remix-run/react";
import { AuthLayout } from "~/components/auth";
import type { OutletContext } from "~/utils/types";

export default function Login() {
  const navigate = useNavigate();
  const { supabase } = useOutletContext<OutletContext>();

  return (
    <AuthLayout
      type="login"
      onSubmit={async (e) => {
        const form = new FormData(e.currentTarget);
        const email = String(form.get("email"));
        const password = String(form.get("password"));
        const { error, data } = await supabase.auth.signInWithPassword({ email, password });
        if (data.user) {
          navigate("/plan");
        }
        return { error: error?.message, success: Boolean(data) };
      }}
    />
  );
}
